import React from "react"

import CSS from "./grid.module.scss"

const Grid = props => {
  return (
    <div
      className={CSS.container}
      style={{ gridTemplateColumns: `repeat(${props.count}, 1fr)` }}
    >
      {props.children}
    </div>
  )
}

export default Grid
