import React from "react"

import CSS from "./text-block.module.scss"

const TextBlock = props => {
  return (
    <div
      className={CSS.container}
      style={{
        textAlign: props.align,
        justifyContent: props.vertical,
      }}
    >
      <article className={CSS.article}>{props.children}</article>
    </div>
  )
}

export default TextBlock
