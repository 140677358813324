import React from "react"

import CSS from "./section.module.scss"

const Section = props => {
  return (
    <section id={props.id} className={`${CSS.wrapper} ${props.themeClass}`}>
      <div className={CSS.container}>{props.children}</div>
    </section>
  )
}

export default Section
