import React from "react"

import CSS from "./columns.module.scss"

const Columns = props => {
  const squeezeMargins = props.count === "1" ? "55rem" : "auto"

  return (
    <div
      className={CSS.container}
      style={{
        gridTemplateColumns: `repeat(${props.count}, 1fr)`,
        textAlign: props.align,
        maxWidth: squeezeMargins,
      }}
    >
      {props.children}
    </div>
  )
}

export default Columns
