import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import CSS from "./hero-banner.module.scss"

const HeroBanner = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "homepage-hero@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={CSS.container}>
      <Img
        className={CSS.hero}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
      <div className={CSS.overlay}>
        <div className={CSS.content}>{props.children}</div>
      </div>
    </div>
  )
}

export default HeroBanner
