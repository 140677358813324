import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import CSS from "./bottle-card.module.scss"

const Bottle = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }
      return <Img alt={props.alt} fluid={image.node.childImageSharp.fluid} />
    }}
  />
)

const BottleCard = props => {
  return (
    <div className={CSS.card}>
      <div className={CSS.bottle}>
        <Bottle filename={props.image} />
      </div>

      <p className={CSS.vintage}>{props.vintage}</p>
      <h3 className={CSS.title}>{props.title}</h3>
      <div className={CSS.description}>{props.children}</div>
    </div>
  )
}

export default BottleCard
