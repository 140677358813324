import React from "react"

import { Swiper, Navigation, Pagination } from "swiper/js/swiper.esm"
import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom"
//   import 'swiper/css/swiper.css'
import "./slider.scss"

const CustomBuildSwiper = props => {
  const params = {
    // Provide Swiper class as props
    Swiper,
    // Add modules you need
    modules: [Navigation, Pagination],
    loop: false,
    speed: 600,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return <ReactIdSwiperCustom {...params}>{props.children}</ReactIdSwiperCustom>
}

export default CustomBuildSwiper
