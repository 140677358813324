import React from "react"
import { graphql, StaticQuery } from "gatsby"

import BackgroundImage from "gatsby-background-image"

const ImageBackground = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality: 90, maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }
      return (
        <BackgroundImage Tag="div" fluid={image.node.childImageSharp.fluid}>
          {/* force full-height */}
          <div style={{ minHeight: `100vh` }}></div>
        </BackgroundImage>
      )
    }}
  />
)

export default ImageBackground
