import React from "react"

import Layout from "../global/layout"
import SEO from "../global/seo"

import HeroBanner from "../components/hero-banner"
import Section from "../components/section"
import Grid from "../components/grid"
import Columns from "../components/columns"
import ColumnsFluid from "../components/columns-fluid"
import Image from "../components/image"
import ImageBackground from "../components/image-background"
import TextBlock from "../components/text-block"
import BottleCard from "../components/bottle-card"

import Slider from "../components/slider"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Section id="home">
      <HeroBanner>
        <div className="logo">
          <img
            src="/ernest-et-emily-logo.svg"
            alt="Ernest & Emily – Domaine Secrete"
          />
        </div>
      </HeroBanner>
    </Section>

    <Section id="intro" themeClass="bg-default">
      <Grid>
        <ImageBackground filename="emily@2x.jpg" />
        <TextBlock align="left" vertical="center">
          <h2>Bienvenue!</h2>
          <p>
            Batnae municipium in Anthemusia conditum Macedonum manu priscorum ab
            Euphrate flumine brevi spatio disparatur, refertum mercatoribus
            opulentis, ubi annua sollemnitate prope Septembris initium mensis ad
            nundinas magna promiscuae fortunae convenit multitudo ad commercanda
            quae Indi mittunt et Seres aliaque plurima vehi terra marique
            consueta.
          </p>
          <p>
            Eo adducta re per Isauriam, rege Persarum bellis finitimis inligato
            repellenteque a conlimitiis suis ferocissimas gentes, quae mente
            quadam versabili hostiliter eum saepe incessunt et in nos arma
            moventem aliquotiens iuvant.
          </p>
          <p>
            <img
              className="autograph"
              src="/autograph-emily@2x.png"
              alt="autograph"
            />
          </p>
        </TextBlock>
      </Grid>
    </Section>

    <Section themeClass="bg-dark">
      <Grid>
        <TextBlock align="left" vertical="center">
          <h2>Welcome!</h2>
          <p>
            Batnae municipium in Anthemusia conditum Macedonum manu priscorum ab
            Euphrate flumine brevi spatio disparatur, refertum mercatoribus
            opulentis.
          </p>
          <p>
            Eo adducta re per Isauriam, rege Persarum bellis finitimis inligato
            repellenteque a conlimitiis suis ferocissimas gentes, quae mente
            quadam versabili hostiliter eum saepe incessunt et in nos arma
            moventem aliquotiens iuvant, Nohodares quidam nomine e numero
            optimatum, incursare Mesopotamiam quotiens copia dederit ordinatus,
            explorabat nostra sollicite, si repperisset usquam locum vi subita
            perrupturus.
          </p>
          <p>
            <img
              className="autograph"
              src="/autograph-ernest@2x.png"
              alt="autograph"
            />
          </p>
        </TextBlock>
        <ImageBackground filename="ernest@2x.jpg" />
      </Grid>
    </Section>

    <Slider>
      <div>
        <Image filename="slider-1.jpg" />
      </div>
      <div>
        <Image filename="slider-2.jpg" />
      </div>
      <div>
        <Image filename="slider-3.jpg" />
      </div>
      <div>
        <Image filename="slider-4.jpg" />
      </div>
    </Slider>

    <div id="wines" className="anchor"></div>
    <Section themeClass="bg-default">
      <Columns count="1" align="center">
        <article>
          <h2>Nos vins secrets</h2>
          <p>
            Batnae municipium in Anthemusia conditum Macedonum manu priscorum ab
            Euphrate flumine brevi spatio disparatur, refertum mercatoribus
            opulentis.
          </p>
        </article>
      </Columns>

      <ColumnsFluid count="3" align="center">
        <BottleCard image="bottle-pse@2x.jpg" title="Julienas" vintage="2009">
          <p>
            Batnae municipium in Anthemusia conditum Macedonum manu priscorum ab
            Euphrate flumine brevi spatio disparatur, refertum mercatoribus
            opulentis.
          </p>
        </BottleCard>

        <BottleCard
          image="bottle-bcdb@2x.jpg"
          title="Morgon les Charmes"
          vintage="2003"
        >
          <p>
            Eo adducta re per Isauriam, rege Persarum bellis finitimis inligato
            repellenteque a conlimitiis suis ferocissimas gentes.
          </p>
        </BottleCard>

        <BottleCard
          image="bottle-graves@2x.jpg"
          title="Puilly-Fuissé le Clos"
          vintage="2012"
        >
          <p>
            Pamphyliam diu quidem intactam sed timore populationum et caedium,
            milite per omnia diffuso propinqua, magnis undique praesidiis
            conmunitam.
          </p>
        </BottleCard>
      </ColumnsFluid>

      <Columns count="1" align="center">
        <h3>Vous pouvez les acheter ici</h3>
        <p><strong>Batnae municipium</strong> — Anthemusia conditum Macedonum manu priscorum ab Euphrate flumine brevi<br/>
        <strong>Metuentes</strong> — Septembris initium mensis promiscuae fortunae convenit<br/>
        <strong>Eo adducta re per Isauriam</strong> — Macedonum manu priscorum ab Euphrate flumine brevi</p>
      </Columns>
    </Section>

    <div id="photos" className="anchor"></div>
    <Section themeClass="bg-default">
      <Grid count="3">
        <Image filename="photo-vines@2x.jpg" />
        <Image filename="photo-tasting@2x.jpg" />
        <Image filename="photo-grapes@2x.jpg" />
        <Image filename="photo-harley@2x.jpg" />
        <Image filename="photo-wine-glasses@2x.jpg" />
        <Image filename="photo-corks@2x.jpg" />
      </Grid>
    </Section>

    <Section id="vineyard" themeClass="bg-light">
      <Grid>
        <ImageBackground filename="vineyard@2x.jpg" />
        <TextBlock align="left" vertical="center">
          <h2>Les vignes</h2>
          <p>
            Batnae municipium in Anthemusia conditum Macedonum manu priscorum ab
            Euphrate flumine brevi spatio disparatur, refertum mercatoribus
            opulentis, ubi annua sollemnitate prope Septembris initium mensis ad
            nundinas.
          </p>
          <p>
            Metuentes igitur idem latrones Lycaoniam magna parte campestrem cum
            se inpares nostris fore congressione stataria documentis
            frequentibus scirent, tramitibus deviis petivere Pamphyliam diu
            quidem intactam sed timore populationum et caedium, milite per omnia
            diffuso propinqua, magnis undique praesidiis conmunitam.
          </p>       
        </TextBlock>
      </Grid>
      <Grid>
        <TextBlock align="left" vertical="center">
          <h2>Le cepage</h2>
          <p>
            Batnae municipium in Anthemusia conditum Macedonum manu priscorum ab
            Euphrate flumine brevi spatio disparatur.
          </p>
          <p>
            Eo adducta re per Isauriam, rege Persarum bellis finitimis inligato
            repellenteque a conlimitiis suis ferocissimas gentes, quae mente
            quadam versabili hostiliter eum saepe incessunt et in nos arma
            moventem aliquotiens iuvant, Nohodares quidam nomine e numero
            optimatum, incursare Mesopotamiam quotiens copia dederit ordinatus,
            explorabat nostra sollicite, si repperisset usquam locum vi subita
            perrupturus.
          </p>
        </TextBlock>
        <ImageBackground filename="grapes@2x.jpg" />
      </Grid>
    </Section>

    <HeroBanner hero="//placehold.it/1440x900/444">
      <p>Ceci est un site Web de démonstration réalisé avec &#9825; par <a href="https://www.vinpro.fr">VinPro</a></p>
    </HeroBanner>

    <Section id="contact"></Section>
  </Layout>
)

export default IndexPage
